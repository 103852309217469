import PropTypes from "prop-types";
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

// project import
import Loader from "components/Loader";
import { SimpleLayoutType } from "config";
import { Box } from "@mui/material";

const Header = lazy(() => import("./Header"));
const FooterBlock = lazy(() => import("./FooterBlock"));

// ==============================|| LAYOUT - SIMPLE / LANDING ||============================== //

const CourseLayout = ({ layout = SimpleLayoutType.SIMPLE }) => {
  return (
    <Suspense fallback={<Loader />}>
      <Header />
      <Box sx={{ position: 'relative', marginTop: 10}}>
        <Outlet />
      </Box>
      <FooterBlock isFull={layout === SimpleLayoutType.LANDING} />
    </Suspense>
  );
};

CourseLayout.propTypes = {
  layout: PropTypes.string,
};

export default CourseLayout;
