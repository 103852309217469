// third-party
import { FormattedMessage } from "react-intl";

// project import

// assets
import {
  DashboardOutlined,
  GoldOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const icons = {
  dashboard: DashboardOutlined,
  components: GoldOutlined,
  loading: LoadingOutlined,
};

// const loadingMenu = {
//   id: "group-dashboard-loading",
//   title: <FormattedMessage id="dashboard" />,
//   type: "group",
//   icon: icons.loading,
//   children: [
//     {
//       id: "dashboard1",
//       title: <FormattedMessage id="dashboard" />,
//       type: "collapse",
//       icon: icons.loading,
//       children: [
//         {
//           id: "default1",
//           title: "loading",
//           type: "item",
//           url: "/dashboard/default",
//           breadcrumbs: false,
//         },
//         {
//           id: "analytics1",
//           title: "loading",
//           type: "item",
//           url: "/dashboard/analytics",
//           breadcrumbs: false,
//         },
//       ],
//     },
//   ],
// };

// ==============================|| MENU ITEMS - API ||============================== //

// export const MenuFromAPI = () => {
//   const { menu, menuLoading } = useGetMenu();

//   if (menuLoading) return loadingMenu;

//   const subChildrenList = (children) => {
//     return children?.map((subList) => {
//       return fillItem(subList);
//     });
//   };

//   const itemList = (subList) => {
//     let list = fillItem(subList);

//     // if collapsible item, we need to feel its children as well
//     if (subList.type === "collapse") {
//       list.children = subChildrenList(subList.children);
//     }
//     return list;
//   };

//   const childrenList = menu?.children?.map((subList) => {
//     return itemList(subList);
//   });

//   let menuList = fillItem(menu, childrenList);
//   return menuList;
// };

// function fillItem(item, children) {
//   return {
//     ...item,
//     title: <FormattedMessage id={`${item?.title}`} />,
//     // @ts-ignore
//     icon: icons[item?.icon],
//     ...(children && { children }),
//   };
// }

//My new Dash Menu by Pruthvi

const dashboard = {
  id: "group-dashboard",
  // title: <FormattedMessage id="Dashboard" />,
  //home button
  icon: icons.dashboard,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="Dashboard" />,
      type: "collapse",
      icon: icons.dashboard,
      children: [
        // {
        //   id: "default",
        //   title: <FormattedMessage id="Home" />,
        //   type: "item",
        //   url: "/dashboard/default",
        //   breadcrumbs: false,
        // },
        // {
        //   id: "analytics",
        //   title: <FormattedMessage id="Analytics" />,
        //   type: "item",
        //   url: "/dashboard/analytics",
        //   breadcrumbs: false,
        // },
        // Available Courses
        // {
        //   id: "available-courses",
        //   title: <FormattedMessage id="Available Courses" />,
        //   type: "item",
        //   url: "/dashboard/available-courses",
        //   breadcrumbs: false,
        // },
        // My Courses
        {
          id: "my-courses",
          title: <FormattedMessage id="My Courses" />,
          type: "item",
          url: "/dashboard/my-courses",
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default dashboard;
