// import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

// project import
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";

// import Loadable from "components/Loadable";

// render - landing page
// const PagesLanding = Loadable(lazy(() => import('pages/landing')));
// const PagesLanding = Loadable(lazy(() => import("pages/auth/login")));

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    // {
    //   path: "/",
    //   // element: <SimpleLayout layout={SimpleLayoutType.LANDING} />,
    //   //login page
    //   element: <SimpleLayout layout={SimpleLayoutType.LANDING} />,
    //   children: [
    //     {
    //       index: true,
    //       element: <PagesLanding />,
    //     },
    //   ],
    // },
    LoginRoutes,
    MainRoutes,
  ],
  { basename: process.env.REACT_APP_BASE_NAME }
);

export default router;
