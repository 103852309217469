// material-ui
// import { useTheme } from "@mui/material/styles";

// project import
// import { useGetMenuMaster } from "api/menu";
import SimpleBar from "components/third-party/SimpleBar";
// import NavCard from "./NavCard";
// import NavUser from "./NavUser";
import Navigation from "./Navigation";
// import WalletCard from "./WalletCard";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  // const theme = useTheme();
  // const { menuMaster } = useGetMenuMaster();
  // const drawerOpen = menuMaster.isDashboardDrawerOpened;
  // const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <SimpleBar
        sx={{
          "& .simplebar-content": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Navigation />
        {/* {drawerOpen && !matchDownMD && <NavCard />} */}
        {/* {drawerOpen && <WalletCard />}
        {drawerOpen && <NavCard />} */}
      </SimpleBar>
      {/* <NavUser /> */}
    </>
  );
};

export default DrawerContent;
