// import { useMemo } from "react";

// material-ui
import { Box, useMediaQuery } from "@mui/material";

// project import
import FullScreen from "./FullScreen";
// import Localization from "./Localization";
// import MegaMenuSection from "./MegaMenuSection";
import MobileSection from "./MobileSection";
import Profile from "./Profile";

// import useConfig from "hooks/useConfig";
import DrawerHeader from "layout/Dashboard/Drawer/DrawerHeader";

import { MenuOrientation } from "config";
import useConfig from "hooks/useConfig";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  // const { i18n, menuOrientation } = useConfig();
  const menuOrientation = useConfig().menuOrientation;

  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const localization = useMemo(() => <Localization />, [i18n]);

  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}
      {/* {!downLG && <Search />} */}
      {!downLG && <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }} />}
      {/* {!downLG && megaMenu} */}
      {/* {!downLG && localization} */}
      {downLG && <Box sx={{ width: "100%", ml: 1 }} />}

      {/* <Notification /> */}
      {/* <Message /> */}
      {!downLG && <FullScreen />}
      {/* <Customization /> */}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
