// project import
import dashboard from "./dashboard";
// import genealogy from "./genealogy";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    dashboard,
    // genealogy,
    // widget,
    // applications,
    // formsTables,
    // chartsMap,
    // samplePage,
    // pages,
    // other,
  ],
};

export default menuItems;
