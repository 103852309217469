import PropTypes from "prop-types";
import React, { useState } from "react";

// material-ui
import {
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// assets
import {
  // EditOutlined,
  LogoutOutlined,
  // ProfileOutlined,
  UserOutlined,
  WalletOutlined,
  // SettingOutlined,
} from "@ant-design/icons";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import Slide from "@mui/material/Slide";
//import Typography from 'themes/overrides/Typography';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Avatar from "components/@extended/Avatar";
import avatar1 from "assets/images/users/avatar-1.png";
import {
  Box,
  // ButtonBase,
  // CardContent,
  // ClickAwayListener,
  Grid,
  // Paper,
  // Popper,
  // Stack,
  Tab,
  Tabs,
  // Tooltip,
  // Typography,
} from "@mui/material";
// import { TimelineOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const userName = JSON.parse(localStorage.getItem("users"))?.user?.fullname;
const Email = JSON.parse(localStorage.getItem("users"))?.user?.email;
const phoneNumber = JSON.parse(localStorage.getItem("users"))?.user
  ?.phonenumber;
const referredBy = JSON.parse(localStorage.getItem("users"))?.user?.referredby;

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

const ProfileTab = ({ handleLogout }) => {
  // const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };
  // const navigationLinks = {
  //   1:"/editProfile",
  //   2:"/viewProfile"
  // }
  const handleListItemClick = (event, index) => {
    if (index === 1) {
      navigate('/profiles/account/basic')
    }
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
    <>
      <List
        component="nav"
        sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32 } }}
      >
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <UserOutlined />
          </ListItemIcon>
          <ListItemText primary="View Profile" />
        </ListItemButton>

        {/* <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <ProfileOutlined />
          </ListItemIcon>
          <ListItemText primary="Social Profile" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemIcon>
            <WalletOutlined />
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItemButton> */}
        <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="profile tabs"
              >
                <Tab
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                  icon={
                    <UserOutlined
                      style={{ marginBottom: 0, marginRight: "10px" }}
                    />
                  }
                  label="About"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                  icon={
                    <WalletOutlined
                      style={{ marginBottom: 0, marginRight: "10px" }}
                    />
                  }
                  label="Wallet"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
          <Box sx={{flexGrow: 1}}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Avatar
                    alt="profile user"
                    src={avatar1}
                    sx={{ width: 80, height: 80 ,marginTop: 8}}
                  />
                </Grid>
                <Grid item xs={8}>
                  <p>Full Name : {userName}</p>
                  <p>Email : {Email}</p>
                  <p>Phone Number : {phoneNumber}</p>
                  <p>Referred By : {referredBy}</p>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} disabled>
              Edit Profile
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default ProfileTab;
