import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
// import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT } from "contexts/auth-reducer/actions";
import authReducer from "contexts/auth-reducer/auth";

// project import
import Loader from "components/Loader";
import axios from "utils/axios";

// const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");

        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get("/api/account/me");
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post("/api/account/login", {
      email,
      password,
    });

    let users = response.data;
    setSession(users.serviceToken);
    window.localStorage.setItem("users", JSON.stringify(users));
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: users,
      },
    });
  };

  const register = async (
    referredby,
    fullname,
    phonenumber,
    email,
    password
  ) => {
    // todo: this flow need to be recode as it not verified
    // const id = chance.bb_pin();

    const response = await axios.post("/api/account/register", {
      referredby,
      fullname,
      phonenumber,
      email,
      password,
    });
    let users = response.data;

    // if (
    //   window.localStorage.getItem("users") !== undefined &&
    //   window.localStorage.getItem("users") !== null
    // ) {
    //   const localUsers = window.localStorage.getItem("users");
    //   users = [...JSON.parse(localUsers)];
    // }
    setSession(users.serviceToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: users,
      },
    });
    window.localStorage.setItem("users", JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    window.localStorage.removeItem("users");
    window.localStorage.removeItem("serviceToken");
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
