import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import { SimpleLayoutType } from "config";
import DashboardLayout from "layout/Dashboard";
import PagesLayout from "layout/Pages";
import SimpleLayout from "layout/Simple";
import CourseLayout from "layout/Course";


// render - dashboard
// const DashboardDefault = Loadable(
//   lazy(() => import("pages/dashboard/default"))
// );
const DashboardAnalytics = Loadable(
  lazy(() => import("pages/dashboard/analytics"))
);
const UserProfile = Loadable(lazy(() => import("pages/apps/profiles/user")));
const UserTabPersonal = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPersonal"))
);
const UserTabPayment = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPayment"))
);
const UserTabPassword = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPassword"))
);
const UserTabSettings = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabSettings"))
);

//User Email Verification
const UserTabEmailVerification = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabEmailVerification"))
);

const AccountProfile = Loadable(
  lazy(() => import("pages/apps/profiles/account"))
);
const AccountTabProfile = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabProfile"))
);
const AccountTabPersonal = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabPersonal"))
);
const AccountTabAccount = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabAccount"))
);
const AccountTabPassword = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabPassword"))
);
const AccountTabRole = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabRole"))
);
const AccountTabSettings = Loadable(
  lazy(() => import("sections/apps/profiles/account/TabSettings"))
);
// pages routing
const AuthLogin = Loadable(lazy(() => import("pages/auth/login")));
const AuthRegister = Loadable(lazy(() => import("pages/auth/register")));
const AuthForgotPassword = Loadable(
  lazy(() => import("pages/auth/forgot-password"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("pages/auth/reset-password"))
);
const AuthCheckMail = Loadable(lazy(() => import("pages/auth/check-mail")));
const AuthCodeVerification = Loadable(
  lazy(() => import("pages/auth/code-verification"))
);

const MaintenanceError = Loadable(lazy(() => import("pages/maintenance/404")));
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/500"))
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction"))
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon"))
);

const AppContactUS = Loadable(lazy(() => import("pages/contact-us")));


// Available Courses
// const AvailableCourses = Loadable(
//   lazy(() => import("pages/courses/available-courses"))
// );

// My Courses
const MyCourses = Loadable(lazy(() => import("pages/courses/my-courses")));

// Direct Referrals
// const DirectReferrals = Loadable(
//   lazy(() => import("pages/referrals/direct-referrals"))
// );

// Single Course View Page
const SingleCourseView = Loadable(
  lazy(() => import("pages/courses/single-course-view"))
);

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
          {
            path: "dashboard",
                children: [
                  {
                    path: "default",
                    element: <MyCourses />,
                  },
                  {
                    path: "analytics",
                    element: <DashboardAnalytics />,
                  },
                  // Available Courses
                  // {
                  //   path: "available-courses",
                  // element: <AvailableCourses />,
                  // },
                  // My Courses
                  {
                    path: "my-courses",
                    element: <MyCourses />,
                  },
                ],
          },
          {
            path: "profiles",
            children: [
              {
                path: "account",
                element: <AccountProfile />,
                children: [
                  {
                    path: "basic",
                    element: <AccountTabProfile />,
                  },
                  {
                    path: "personal",
                    element: <AccountTabPersonal />,
                  },
                  {
                    path: "my-account",
                    element: <AccountTabAccount />,
                  },
                  {
                    path: "password",
                    element: <AccountTabPassword />,
                  },
                  {
                    path: "role",
                    element: <AccountTabRole />,
                  },
                  {
                    path: "settings",
                    element: <AccountTabSettings />,
                  },
                ],
              },
              {
                path: "user",
                element: <UserProfile />,
                children: [
                  {
                    path: "personal",
                    element: <UserTabPersonal />,
                  },
                  {
                    path: "payment",
                    element: <UserTabPayment />,
                  },
                  {
                    path: "password",
                    element: <UserTabPassword />,
                  },
                  {
                    path: "settings",
                    element: <UserTabSettings />,
                  },
                  // Email verification
                  {
                    path: "email-verification",
                    element: <UserTabEmailVerification />,
                  },
                ],
              },
            ],
          },
          // {
          //   path: "genealogy",
          //   children: [
          //     {
          //       path: "direct-referrals",
          //       element: <DirectReferrals />,
          //     },
          //   ],
          // },
        ],
    },
    {
      path: "/maintenance",
      element: <PagesLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
    {
      path: "/auth",
      element: <PagesLayout />,
      children: [
        {
          path: "login",
          element: <AuthLogin />,
        },
        {
          path: "register",
          element: <AuthRegister />,
        },
        {
          path: "forgot-password",
          element: <AuthForgotPassword />,
        },
        {
          path: "reset-password",
          element: <AuthResetPassword />,
        },
        {
          path: "check-mail",
          element: <AuthCheckMail />,
        },
        {
          path: "code-verification",
          element: <AuthCodeVerification />,
        },
      ],
    },
    {
      path: "/",
      element: <SimpleLayout layout={SimpleLayoutType.SIMPLE} />,
      children: [
        {
          path: "contact-us",
          element: <AppContactUS />,
        },
      ],
    },
    // Single Course View Page
    {
      path: "courses",
      element: <CourseLayout />,
      children: [
        {
          path: "single-course-view/:courseId",
          element: <SingleCourseView />,
        },
      ],
    },
    ],
};

export default MainRoutes;
