import PropTypes from "prop-types";

// Import Logo from assets/images/logo.jpg
import Logo from "assets/images/logo.jpg";

// material-ui
// import { useTheme } from "@mui/material/styles";

// project-import

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse }) => {
  // const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <>
      {/* Image : assets/logo.jpg */}
      <img src={Logo} alt="Mantis" width="100" />
    </>
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool,
};

export default LogoMain;
