import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// project import
import useAuth from "hooks/useAuth";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //fetch users data from local storage
    // const users = localStorage.getItem("users");
    // const usersData = JSON.parse(users);

    if (!isLoggedIn) {
      // navigate("login", {
      //   state: {
      //     from: location.pathname,
      //   },
      //   replace: true,
      // });
      navigate("login", { replace: true });
    }
    // if logged in and accountstatus is "inactive" then redirect to account activation page
    // if (isLoggedIn && usersData.accountstatus === "inactive") {
    //   navigate("/pages/account-activation", { replace: true });
    // }
  }, [isLoggedIn, navigate, location]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
